import { kinds } from "nostr-tools";
import { isParameterizedReplaceableKind } from "nostr-tools/kinds";
import { isATag, isETag } from "./tags.js";
import { unixNow } from "./time.js";
import { getATagFromAddressPointer, getETagFromEventPointer } from "./pointers.js";
import { getTagValue } from "./index.js";
export function getDeleteIds(deleteEvent) {
    return deleteEvent.tags.filter(isETag).map((t) => t[1]);
}
export function getDeleteCoordinates(deleteEvent) {
    return deleteEvent.tags.filter(isATag).map((t) => t[1]);
}
/** Creates a NIP-09 delete event for an array of events */
export function createDeleteEvent(events, message) {
    const eventPointers = [];
    const addressPointers = [];
    const eventKinds = new Set();
    for (const event of events) {
        eventKinds.add(event.kind);
        eventPointers.push({ id: event.id });
        if (isParameterizedReplaceableKind(event.kind)) {
            const identifier = getTagValue(event, "d");
            if (!identifier)
                throw new Error("Event missing identifier");
            addressPointers.push({ pubkey: event.pubkey, kind: event.kind, identifier });
        }
    }
    return {
        kind: kinds.EventDeletion,
        content: message ?? "",
        tags: [
            ...eventPointers.map(getETagFromEventPointer),
            ...addressPointers.map(getATagFromAddressPointer),
            ...Array.from(eventKinds).map((k) => ["k", String(k)]),
        ],
        created_at: unixNow(),
    };
}
