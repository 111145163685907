import { findAndReplace } from "mdast-util-find-and-replace";
import { decode } from "nostr-tools/nip19";
import Expressions from "../helpers/regexp.js";
export function remarkNostrMentions() {
    return (tree) => {
        findAndReplace(tree, [
            Expressions.nostrLink,
            (_, $1) => {
                try {
                    return {
                        type: "link",
                        data: decode($1),
                        children: [],
                        url: "nostr:" + $1,
                    };
                }
                catch (error) { }
                return false;
            },
        ]);
    };
}
