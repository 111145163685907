import { bech32 } from "@scure/base";
import { parseBolt11 } from "./bolt11.js";
const decoder = new TextDecoder();
export function parseLightningAddress(address) {
    let [name, domain] = address.split("@");
    if (!name || !domain)
        return;
    return new URL(`https://${domain}/.well-known/lnurlp/${name}`);
}
export function decodeLNURL(lnurl) {
    try {
        const { words, prefix } = bech32.decode(lnurl);
        if (prefix !== "lnurl")
            return;
        const str = decoder.decode(bech32.fromWords(words));
        return new URL(str);
    }
    catch (error) { }
    return undefined;
}
export function parseLNURLOrAddress(addressOrLNURL) {
    if (addressOrLNURL.includes("@")) {
        return parseLightningAddress(addressOrLNURL);
    }
    return decodeLNURL(addressOrLNURL);
}
export async function getInvoice(callback) {
    const { pr: payRequest } = await fetch(callback).then((res) => res.json());
    const amount = callback.searchParams.get("amount");
    if (!amount)
        throw new Error("Missing amount");
    if (payRequest) {
        const parsed = parseBolt11(payRequest);
        if (parsed.amount !== parseInt(amount))
            throw new Error("Incorrect amount");
        return payRequest;
    }
    else
        throw new Error("Failed to get invoice");
}
