import { useState, useEffect } from "react";
export function getCurrentValue(observable) {
    if (Reflect.has(observable, "value"))
        return Reflect.get(observable, "value");
    return undefined;
}
export function useObservable(observable) {
    const current = observable && getCurrentValue(observable);
    const [_count, setCount] = useState(0);
    const [value, setValue] = useState(current);
    useEffect(() => {
        // Reset the state, the method passed to subscribe will NOT always be called
        setValue(observable && getCurrentValue(observable));
        const sub = observable?.subscribe((v) => {
            setValue(v);
            setCount((c) => c + 1);
        });
        return () => sub?.unsubscribe();
    }, [observable, setValue, setCount]);
    return current || value;
}
