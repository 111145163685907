import { unixNow } from "applesauce-core/helpers";
import { kinds } from "nostr-tools";
export const HiddenTagsSymbol = Symbol.for("hidden-tags");
export const EventsWithHiddenTags = [
    37375, // NIP-60 wallet
    // NIP-51 lists
    kinds.BookmarkList,
    kinds.InterestsList,
    kinds.Mutelist,
    kinds.CommunitiesList,
    kinds.PublicChatsList,
    kinds.SearchRelaysList,
    kinds.SearchRelaysList,
    10009, // NIP-29 groups
    // NIP-51 sets
    kinds.Bookmarksets,
    kinds.Relaysets,
    kinds.Followsets,
    kinds.Curationsets,
    kinds.Interestsets,
];
/** Checks if an event can have hidden tags */
export function canHaveHiddenTags(event) {
    return EventsWithHiddenTags.includes(event.kind);
}
/** Checks if an event has hidden tags */
export function hasHiddenTags(event) {
    return canHaveHiddenTags(event) && event.content.length > 0;
}
/** Returns the hidden tags from an event if they are unlocked */
export function getHiddenTags(event) {
    return Reflect.get(event, HiddenTagsSymbol);
}
export function isHiddenTagsLocked(event) {
    return hasHiddenTags(event) && getHiddenTags(event) === undefined;
}
/**
 * Decrypts the private list
 * @param event The list event to decrypt
 * @param signer A signer to use to decrypt the tags
 * @param store An optional EventStore to notify about the update
 */
export async function unlockHiddenTags(event, signer, store) {
    const plaintext = await signer.nip04.decrypt(event.pubkey, event.content);
    const parsed = JSON.parse(plaintext);
    if (!Array.isArray(parsed))
        throw new Error("Content is not an array of tags");
    // Convert array to tags array string[][]
    const tags = parsed.filter((t) => Array.isArray(t)).map((t) => t.map((v) => String(v)));
    Reflect.set(event, HiddenTagsSymbol, tags);
    if (store)
        store.update(event);
    return event;
}
/**
 * Modifies tags and returns an EventTemplate
 * @param event Event to modify
 * @param operations Operations for hidden and public tags
 * @param signer A signer to use to decrypt the tags
 */
export async function modifyEventTags(event, operations, signer) {
    const draft = { content: event.content, tags: event.tags, kind: event.kind, created_at: unixNow() };
    if (operations.public) {
        draft.tags = operations.public(event.tags);
    }
    if (operations.hidden) {
        if (!signer)
            throw new Error("Missing signer for hidden tags");
        if (!canHaveHiddenTags(event))
            throw new Error("Event can not have hidden tags");
        const hidden = hasHiddenTags(event) ? getHiddenTags(event) : [];
        if (!hidden)
            throw new Error("Hidden tags are locked");
        const newHidden = operations.hidden(hidden);
        draft.content = await signer.nip04.encrypt(event.pubkey, JSON.stringify(newHidden));
    }
    return draft;
}
/**
 * Override the hidden tags in an event
 */
export async function overrideHiddenTags(event, hidden, signer) {
    const ciphertext = await signer.nip04.encrypt(event.pubkey, JSON.stringify(hidden));
    return {
        kind: event.kind,
        content: ciphertext,
        created_at: unixNow(),
        tags: event.tags,
    };
}
