import { decode } from "light-bolt11-decoder";
/** Parses a lightning invoice */
export function parseBolt11(paymentRequest) {
    const decoded = decode(paymentRequest);
    const timestamp = decoded.sections.find((s) => s.name === "timestamp")?.value ?? 0;
    const description = decoded.sections.find((s) => s.name === "description")?.value ?? "";
    const amount = parseInt(decoded.sections.find((s) => s.name === "amount")?.value ?? "0");
    return {
        paymentRequest: decoded.paymentRequest,
        description: description,
        amount: amount,
        timestamp: timestamp,
        expiry: timestamp + decoded.expiry,
    };
}
