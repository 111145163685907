import { LRU } from "../helpers/lru.js";
import * as Queries from "../queries/index.js";
import { shareLatestValue } from "../observable/share-latest-value.js";
export class QueryStore {
    static Queries = Queries;
    store;
    constructor(store) {
        this.store = store;
    }
    queries = new LRU();
    observables = new WeakMap();
    /** Creates a cached query */
    runQuery(queryConstructor) {
        return (...args) => {
            const tempQuery = queryConstructor(...args);
            const key = `${queryConstructor.name}|${tempQuery.key}`;
            let query = this.queries.get(key);
            if (!query) {
                query = tempQuery;
                this.queries.set(key, tempQuery);
            }
            if (!this.observables.has(query)) {
                query.args = args;
                const observable = query.run(this.store, this).pipe(shareLatestValue());
                this.observables.set(query, observable);
                return observable;
            }
            return this.observables.get(query);
        };
    }
    /** Returns a single event */
    event(id) {
        return this.runQuery(Queries.SingleEventQuery)(id);
    }
    /** Returns a single event */
    events(ids) {
        return this.runQuery(Queries.MultipleEventsQuery)(ids);
    }
    /** Returns the latest version of a replaceable event */
    replaceable(kind, pubkey, d) {
        return this.runQuery(Queries.ReplaceableQuery)(kind, pubkey, d);
    }
    /** Returns a directory of events by their UID */
    replaceableSet(pointers) {
        return this.runQuery(Queries.ReplaceableSetQuery)(pointers);
    }
    /** Returns an array of events that match the filter */
    timeline(filters, keepOldVersions) {
        return this.runQuery(Queries.TimelineQuery)(filters, keepOldVersions);
    }
    /** Returns the parsed profile (0) for a pubkey */
    profile(pubkey) {
        return this.runQuery(Queries.ProfileQuery)(pubkey);
    }
    /** Returns all reactions for an event (supports replaceable events) */
    reactions(event) {
        return this.runQuery(Queries.ReactionsQuery)(event);
    }
    /** Returns the parsed relay list (10002) for the pubkey */
    mailboxes(pubkey) {
        return this.runQuery(Queries.MailboxesQuery)(pubkey);
    }
    thread(root) {
        return this.runQuery(Queries.ThreadQuery)(root);
    }
}
export { Queries };
