/** Tests if a string is hex */
export function isHex(str) {
    if (str?.match(/^[0-9a-f]+$/i))
        return true;
    return false;
}
/** Tests if a string is a 64 length hex string */
export function isHexKey(key) {
    if (key?.toLowerCase()?.match(/^[0-9a-f]{64}$/))
        return true;
    return false;
}
export function stripInvisibleChar(str) {
    return str && str.replaceAll(/[\p{Cf}\p{Zs}]/gu, "");
}
