import { getTagValue } from "./event.js";
export function getEmojiTag(event, code) {
    code = code.replace(/^:|:$/g, "").toLocaleLowerCase();
    return event.tags.filter((t) => t[0] === "emoji" && t[1] && t[2]).find((t) => t[1].toLowerCase() === code);
}
/** Returns the name of a NIP-30 emoji pack */
export function getPackName(pack) {
    return getTagValue(pack, "title") || getTagValue(pack, "d");
}
/** Returns an array of emojis from a NIP-30 emoji pack */
export function getEmojis(pack) {
    return pack.tags
        .filter((t) => t[0] === "emoji" && t[1] && t[2])
        .map((t) => ({ name: t[1], url: t[2] }));
}
